import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import LandingVideo from "../components/Landing/LandingVideo"
import LandingSignup from "../components/Landing/LandingSignup"
import LandingHover from "../components/Landing/LandingHover"
import LandingLifestyle from "../components/Landing/LandingLifestyle"
import LandingInnovative from "../components/Landing/LandingInnovative"
import LandingSoftware from "../components/Landing/LandingSoftware"
import LandingOS from "../components/Landing/LandingOS"
import LandingTestimonials from "../components/Landing/LandingTestimonials"
import LandingPress from "../components/Landing/LandingPress"
import LandingSignupImage from "../components/Landing/LandingSignupImage"
import LandingAbout from "../components/Landing/LandingAbout"
import LandingSocial from "../components/Landing/LandingSocial"
import LandingYoutube from "../components/Landing/LandingYoutube"



const IndexPage = ({ data, location }) => {

  return (
    <Layout data={data.meta} location={location}>
      <SiteMetadata title="ChonkerKeys" description="Keypads for video and computing" />
      <LandingVideo content={data.content} iconImage={data.iconsImage} heroImage={data.heroImage} />
      <LandingYoutube content={data.content.frontmatter.youtube} />
      <LandingHover content={data.content.frontmatter.hover} />
      <LandingLifestyle content={data.content.frontmatter.lifestyle} images={data.lifestyleImages} />
      <LandingInnovative content={data.content.frontmatter.innovative} />
      <LandingSoftware content={data.content.frontmatter.software} />
      <LandingOS content={data.content.frontmatter.os} />
      <div>
        <LandingSocial content={data.content.frontmatter.social} />
      </div>
      <LandingTestimonials content={data.content.frontmatter.testimonial} images={data.testimonialImages} />
      <LandingPress content={data.content.frontmatter.press} />
      <div className="py-12" id="signupimage">
        <LandingSignupImage />
      </div>
      <LandingAbout content={data.content.frontmatter.about} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query LandingEnUsQuery {
    meta: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    content: markdownRemark(frontmatter: {slug: {eq: "landing-en"}}) {
      frontmatter {
        videoSection {
          cta
          icons {
            id
            title
          }
          title
        }
        hover: hoverSection {
          subtext
          title
        }
        youtube: youtubeSection {
          title
          subtext
        }
        lifestyle: lifestyleSection {
          title
          subtext
          images {
            id
            title
          }
        }
        innovative: innovativeSection {
          title
          subtext
        }
        software: softwareSection {
          title
          subtext
        }
        os: osSection {
          title
          subtext
        }
        social: socialSection {
          title
          icons {
            url
          }
        }
        testimonial: testimonialSection {
          title
          testimony {
            testimony
            url
          }
        }
        press: pressSection {
          title
          subtext
        }
        about: aboutSection {
          title
          subtext
        }
      }
    }
    iconsImage: allFile(filter: {relativePath: {regex: "/landing/icons/"}}) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData (
              width: 50
              layout: FIXED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
    lifestyleImages: allFile(filter: {relativePath: {regex: "/landing/lifestyle/"}}) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData (
              width: 300
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
    testimonialImages: allFile(filter: {relativePath: {regex: "/en-US/testimonial/"}}) {
      edges {
        node {
          id
          name
          childImageSharp {
            gatsbyImageData (
              width: 200
              layout: CONSTRAINED
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
    heroImage: file(name: {eq: "CK"}) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          quality: 100
          layout: CONSTRAINED
          placeholder: BLURRED
          formats: [AUTO, WEBP]
        )
      }
    }
    # video: contentfulPageHero(title: {eq: "Landing > Video"}) {
    #   subtext
    #   ctaTitle
    #   heroImage {
    #     localFile {
    #       childImageSharp {
    #         gatsbyImageData(
    #           width: 600
    #           layout: CONSTRAINED
    #           placeholder: BLURRED
    #           formats: [AUTO, WEBP]
    #         )
    #       }
    #     }
    #   }
    #   iconsWithText {
    #     ... on ContentfulImageWithText {
    #       text
    #       image {
    #         media {
    #           localFile {
    #             childImageSharp {
    #               gatsbyImageData (
    #                 width: 50
    #                 layout: FIXED
    #                 placeholder: BLURRED
    #                 formats: [AUTO, WEBP]
    #               )
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }
    #   backgroundVideo {
    #     media {
    #       file {
    #         url
    #       }
    #       localFile {
    #         publicURL
    #       }
    #     }
    #   }
    # }
    # hover: contentfulPageHero(title: {eq: "Landing > Hover"}) {
    #   heroText
    #   subtext
    #   heroImage {
    #     localFile {
    #       publicURL
    #       relativePath
    #       absolutePath
    #       childImageSharp {
    #         gatsbyImageData(
    #           layout: CONSTRAINED
    #           placeholder: BLURRED
    #           formats: [AUTO, WEBP]
    #         )
    #       }
    #     }
    #   }
    # }
    # lifestyle: contentfulPageHero(title: {eq: "Landing > Lifestyle"}) {
    #   heroText
    #   subtext
    #   iconsWithText {
    #     ... on ContentfulImageWithText {
    #       text
    #       image {
    #         media {
    #           localFile {
    #             childImageSharp {
    #               gatsbyImageData (
    #                 width: 300
    #                 layout: CONSTRAINED
    #                 placeholder: BLURRED
    #                 formats: [AUTO, WEBP]
    #               )
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
    # os: contentfulPageHero(title: {eq: "Landing > OS"}) {
    #   heroText
    #   subtext
    # }
    # software: contentfulPageHero(title: {eq: "Landing > Software"}) {
    #   heroText
    #   subtext
    # }
    # social: contentfulPageHero(title: {eq: "Landing > Social"}) {
    #   heroText
    #   subtext
    #   iconsWithText {
    #     ... on ContentfulImageWithText {
    #       text
    #       internalTitle
    #       image {
    #         media {
    #           localFile {
    #             childImageSharp {
    #               gatsbyImageData (
    #                 width: 100
    #                 layout: CONSTRAINED
    #                 placeholder: BLURRED
    #                 formats: [AUTO, WEBP]
    #               )
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
    # innovative: contentfulPageHero(title: {eq: "Landing > Innovative"}) {
    #   heroText
    #   subtext
    #   heroImage {
    #     localFile {
    #       publicURL
    #     }
    #   }
    # }
    # testimonials: contentfulPageHero(title: {eq: "Landing > Testimonials"}) {
    #   heroText
    #   iconsWithText {
    #     ... on ContentfulImageWithText {
    #       text
    #       image {
    #         title
    #         media {
    #           localFile {
    #             name
    #             childImageSharp {
    #               gatsbyImageData (
    #                 width: 200
    #                 layout: CONSTRAINED
    #                 placeholder: BLURRED
    #                 formats: [AUTO, WEBP]
    #               )
    #             }
    #           }
    #         }
    #       }
    #     }
    #   }
    # }
    # press: contentfulPageHero(title: {eq: "Landing > Press"}) {
    #   heroText
    #   heroImage {
    #     localFile {
    #       childImageSharp {
    #         gatsbyImageData (
    #           layout: CONSTRAINED
    #           placeholder: BLURRED
    #           formats: [AUTO, WEBP]
    #         )
    #       }
    #     }
    #   }
    #   subtext
    # }
    # about: contentfulPageHero(title: {eq: "Landing > About"}) {
    #   heroText
    #   heroImage {
    #     localFile {
    #       childImageSharp {
    #         gatsbyImageData (
    #           layout: CONSTRAINED
    #           placeholder: BLURRED
    #           formats: [AUTO, WEBP]
    #         )
    #       }
    #     }
    #   }
    #   description {
    #     raw
    #   }    
    # }
    # signup: contentfulPageHero(title: {eq: "Landing > Signup"}) {
    #   heroText
    #   ctaTitle
    #   subtext
    #   description {
    #     raw
    #   }    
    # }
  }
`
